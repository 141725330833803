import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import styles from "./dest.module.scss"
import classNames from "classnames/bind"
import Layout from "../components/Layout"
import SEO from "../components/seo"
// import HeaderTitle from "../components/HeaderTitle"
import authors from "../../content/authors.yml"
import CardListSection from "../components/Destination/CardListSection"
import Waves from "../components/Destination/Waves"

const cx = classNames.bind(styles)

export const pageQuery = graphql`
  query DestQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulDestination(slug: { eq: $slug }) {
      country
      description {
        childMarkdownRemark {
          html
        }
      }
      photo {
        fluid {
          src
        }
      }
      slug
      title
      updatedAt(formatString: "YYYY/MM")
      article {
        updatedAt
        title
        slug
        content {
          childMarkdownRemark {
            excerpt(pruneLength: 20, truncate: true)
          }
        }
        destination {
          slug
        }
        headerImage {
          fluid {
            src
          }
        }
        suburb
        toc
      }
    }
  }
`

class Destination extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newPos: 0,
    }
  }

  render() {
    const { location, data } = this.props
    const {
      description,
      photo,
      slug,
      title,
      updatedAt,
      article,
    } = data.contentfulDestination

    return (
      <Layout location={location} destination={title}>
        <SEO title={title} />

        <section
          className={cx("sectionImgTitle")}
          style={{ backgroundImage: `url(${photo.fluid.src})` }}
        >
          <div className="sectionContainer">
            <div className={cx("titleWrapper")}>
              <div className={cx("dest")}>여행지</div>
              <p className={cx("title")}>
                {title} {slug.toUpperCase()}
              </p>
              {/* <p className={cx("infoMeta")}>업데이트: {updatedAt}</p> */}
            </div>
          </div>
        </section>

        <section className="w100">
          <div className="sectionContainer">
            <div className="articleContainer">
              <div className="articleContent">
                <div
                  className="markdown"
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <Waves
          waveColor={"rgb(235, 242, 252)"}
          bottomColor={"rgb(235, 242, 252)"}
        />

        <CardListSection article={article} />

        {/* <section
          className={cx("sectionImageCropBox")}
          style={{ backgroundImage: `url(${photo.fluid.src})` }}
        >
          <p className={cx("title")}>{title}</p>
        </section> */}

        {/* <section className={cx("headerSection")}>
          <div className="sectionContainer">
            <div className={cx("titleWrapper")}>
              <p>{slug.toUpperCase()}</p>
            </div>
          </div>
        </section> */}
      </Layout>
    )
  }

  _handleScroll = event => {
    let scrollTop = event.srcElement.scrollingElement.scrollTop
    this.setState({ newPos: scrollTop })
  }

  componentDidMount() {
    // console.log("=== Destination componentDidMount ===")
    // window.addEventListener("scroll", this._handleScroll)
  }

  componentWillUnmount() {
    // console.log("=== Destination componentWillUnmount ===")
    // window.removeEventListener("scroll", this._handleScroll)
  }
}

export default Destination
