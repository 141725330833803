import React from "react"
import classNames from "classnames/bind"
import styles from "./CardListSection.module.scss"
import ArticleCard from "./ArticleCard"
import LayoutContainer from "../Common/LayoutContainer"

const cx = classNames.bind(styles)

const CardListSection = ({ article }) => {
  return (
    <section className={cx("sectionArticleWrapper")}>
      <LayoutContainer>
        <ul className={cx("ulArticlesList")}>
          {article &&
            article
              .sort((a, b) => {
                if (a.toc < b.toc) return -1
                if (a.toc > b.toc) return 1
                return 0
              })
              .map(item => <ArticleCard key={item.slug} item={item} />)}
        </ul>
      </LayoutContainer>
    </section>
  )
}

export default CardListSection
