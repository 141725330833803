import React from "react"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import styles from "./ArticleCard.module.scss"

const cx = classNames.bind(styles)

const ArticleCard = ({ item }) => {
  const { destination, slug, title, headerImage, content, suburb } = item
  return (
    <li className={cx("liItemCard")}>
      <Link
        to={`/dest/${destination.slug}/a/${slug}`}
        className={cx("cardContent")}
      >
        <p className={cx("title")}>{title}</p>
        {headerImage && (
          <img
            src={headerImage.fluid.src}
            width="100%"
            alt="destination header"
          />
        )}
        <p className={cx("excerpt")}>{content.childMarkdownRemark.excerpt}</p>
        {/* {suburb && <p className={cx("suburb")}>{suburb}</p>} */}
      </Link>
    </li>
  )
}

export default ArticleCard
